import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'

Vue.use(VueRouter)

const routerConfig = [{
		path: '/login',
		name: '/login',
		component: () => import('../views/login.vue')
	},
	{
		path: '/',
		redirect: '/home',
		component: () => import('../views/nav.vue'),
		children: [{
				path: '/home',
				name: '/home',
				component: () => import('../views/home.vue')
			},
			{
				path: '/equipment',
				name: '/equipment',
				component: () => import('../views/equipment.vue')

			},
			{
				path: '/eqStatus',
				name: '/eqStatus',
				component: () => import('../views/eqStatus.vue'),
				meta: {
					showSwitchBar: false
				}
			},
			{
				path: '/versionPage',
				name: '/versionPage',
				component: () => import('../views/versionPage.vue'),
				meta: {
					showSwitchBar: false
				}
			},
			{
				path: '/eqGoods',
				name: '/eqGoods',
				component: () => import('../views/eqGoods.vue'),
				meta: {
					showSwitchBar: false
				}
			},
			{
				path: '/eqMonth',
				name: '/eqMonth',
				component: () => import('../views/eqMonth.vue'),
				meta: {
					showSwitchBar: false
				}
			},
			{
				path: '/dayOrders',
				name: '/dayOrders',
				component: () => import('../views/dayOrders.vue'),
				meta: {
					showSwitchBar: false
				}
			},
			{
				path: '/DetailPage',
				name: '/DetailPage',
				component: () => import('../views/DetailPage.vue'),
				meta: {
					showSwitchBar: false
				}
			},
			{
				path: '/logs',
				name: '/logs',
				component: () => import('../views/logs.vue'),
				meta: {
					showSwitchBar: false
				}
			},
			{
				path: '/airCurtain',
				name: '/airCurtain',
				component: () => import('../views/airCurtain.vue'),
				meta: {
					showSwitchBar: false
				}
			},
			{
				path: '/iceCreamControl',
				name: '/iceCreamControl',
				component: () => import('../views/iceCreamControl.vue'),
				meta: {
					showSwitchBar: false
				}
			},
			{
				path: '/openCloseToday',
				name: '/openCloseToday',
				component: () => import('../views/openCloseToday.vue'),
				meta: {
					showSwitchBar: false
				}
			},
			{
				path: '/adjustVolume',
				name: '/adjustVolume',
				component: () => import('../views/adjustVolume.vue'),
				meta: {
					showSwitchBar: false
				}
			},
			{
				path: '/fkConfig',
				name: '/fkConfig',
				component: () => import('../views/fkConfig.vue'),
				meta: {
					showSwitchBar: false
				}
			},
			{
				path: '/parameterSetting',
				name: '/parameterSetting',
				component: () => import('../views/parameterSetting.vue'),
				meta: {
					showSwitchBar: false
				}
			},
			
		]
	}
]

let router = new VueRouter({
	mode: 'hash',
	routes: routerConfig
})
router.beforeEach((to, from, next) => {
	console.log('前置路由守卫', to, from, store)
	
	if(!localStorage.getItem( 'login')&& to.path !== '/login' ){
		next("/login")
	}else{
		next()
	}

	if (to.meta.showSwitchBar === false) {
		store.commit('setShowSwitchBar', false)
	} else {
		store.commit('setShowSwitchBar', true)
	}
	


})
export default router
