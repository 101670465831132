<template>
	<div id="app">
		<router-view />
	</div>
</template>
<script>
	export default {
		data() {
			return {

			}


		},
		created() {
			document.title = "更酷运维后台"
			new Date().format()
		},
		methods: {

		}

	}
</script>
<style>
	* {
		margin: 0;
		padding: 0;
	}

	html {
		font-size: calc(100vw/750);
		background-color: #e1edf6;
	}

	/*
	  ColorUi for MP-weixin  v2.1.4 | by 文晓港 2019年4月25日19:15:42
	  仅供学习交流，如作它用所承受的法律责任一概与作者无关
	  使用ColorUi开发扩展与插件时，请注明基于ColorUi开发
	  
	  （QQ交流群：240787041）
	*/

	/* ==================
	        初始化
	 ==================== */
	page {
		/* Color 可以自定义相关配色 */
		/* var属性兼容性 --> https://www.caniuse.com/#feat=css-variables */
		/* 标准色 */
		--red: #e54d42;
		--orange: #f37b1d;
		--yellow: #fbbd08;
		--olive: #8dc63f;
		--green: #39b54a;
		--cyan: #1cbbb4;
		--blue: #0081ff;
		--purple: #6739b6;
		--mauve: #9c26b0;
		--pink: #e03997;
		--brown: #a5673f;
		--grey: #8799a3;
		--black: #333333;
		--darkGray: #666666;
		--gray: #aaaaaa;
		--ghostWhite: #f1f1f1;
		--white: #ffffff;
		/* 浅色 */
		--redLight: #fadbd9;
		--orangeLight: #fde6d2;
		--yellowLight: #fef2ce;
		--oliveLight: #e8f4d9;
		--greenLight: #d7f0db;
		--cyanLight: #d2f1f0;
		--blueLight: #cce6ff;
		--purpleLight: #e1d7f0;
		--mauveLight: #ebd4ef;
		--pinkLight: #f9d7ea;
		--brownLight: #ede1d9;
		--greyLight: #e7ebed;
		/* 渐变色 */
		--gradualRed: linear-gradient(45deg, #f43f3b, #ec008c);
		--gradualOrange: linear-gradient(45deg, #ff9700, #ed1c24);
		--gradualGreen: linear-gradient(45deg, #39b54a, #8dc63f);
		--gradualPurple: linear-gradient(45deg, #9000ff, #5e00ff);
		--gradualPink: linear-gradient(45deg, #ec008c, #6739b6);
		--gradualBlue: linear-gradient(45deg, #0081ff, #1cbbb4);
		/* 阴影透明色 */
		--ShadowSize: 6rem 6rem 8rem;
		--redShadow: rgba(204, 69, 59, 0.2);
		--orangeShadow: rgba(217, 109, 26, 0.2);
		--yellowShadow: rgba(224, 170, 7, 0.2);
		--oliveShadow: rgba(124, 173, 55, 0.2);
		--greenShadow: rgba(48, 156, 63, 0.2);
		--cyanShadow: rgba(28, 187, 180, 0.2);
		--blueShadow: rgba(0, 102, 204, 0.2);
		--purpleShadow: rgba(88, 48, 156, 0.2);
		--mauveShadow: rgba(133, 33, 150, 0.2);
		--pinkShadow: rgba(199, 50, 134, 0.2);
		--brownShadow: rgba(140, 88, 53, 0.2);
		--greyShadow: rgba(114, 130, 138, 0.2);
		--grayShadow: rgba(114, 130, 138, 0.2);
		--blackShadow: rgba(26, 26, 26, 0.2);

		background-color: var(--ghostWhite);
		font-size: 28rem;
		color: var(--black);
		font-family: Helvetica Neue, Helvetica, sans-serif;
	}

	view,
	scroll-view,
	swiper,
	button,
	input,
	textarea,
	label,
	navigator,
	image {
		box-sizing: border-box;
	}

	.round {
		border-radius: 5000rem;
	}

	.radius {
		border-radius: 6rem;
	}

	/* ==================
	          图片
	 ==================== */

	image {
		max-width: 100%;
		display: inline-block;
		position: relative;
		z-index: 0;
	}

	image.loading::before {
		content: "";
		background-color: #f5f5f5;
		display: block;
		position: absolute;
		width: 100%;
		height: 100%;
		z-index: -2;
	}

	image.loading::after {
		content: "\e7f1";
		font-family: "cuIcon";
		position: absolute;
		top: 0;
		left: 0;
		width: 32rem;
		height: 32rem;
		line-height: 32rem;
		right: 0;
		bottom: 0;
		z-index: -1;
		font-size: 32rem;
		margin: auto;
		color: #ccc;
		-webkit-animation: cuIcon-spin 2s infinite linear;
		animation: cuIcon-spin 2s infinite linear;
		display: block;
	}

	.response {
		width: 100%;
	}

	/* ==================
	         开关
	 ==================== */

	switch,
	checkbox,
	radio {
		position: relative;
	}

	switch::after,
	switch::before {
		font-family: "cuIcon";
		content: "\e645";
		position: absolute;
		color: var(--white) !important;
		top: 0%;
		left: 0rem;
		font-size: 26rem;
		line-height: 26rem;
		width: 50%;
		text-align: center;
		pointer-events: none;
		transform: scale(0, 0);
		transition: all 0.3s ease-in-out 0s;
		z-index: 9;
		bottom: 0;
		height: 26rem;
		margin: auto;
	}

	switch::before {
		content: "\e646";
		right: 0;
		transform: scale(1, 1);
		left: auto;
	}

	switch[checked]::after,
	switch.checked::after {
		transform: scale(1, 1);
	}

	switch[checked]::before,
	switch.checked::before {
		transform: scale(0, 0);
	}

	switch[checked]::before {
		transform: scale(0, 0);
	}

	radio::before,
	checkbox::before {
		font-family: "cuIcon";
		content: "\e645";
		position: absolute;
		color: var(--white) !important;
		top: 50%;
		margin-top: -8rem;
		right: 5rem;
		font-size: 32rem;
		line-height: 16rem;
		pointer-events: none;
		transform: scale(1, 1);
		transition: all 0.3s ease-in-out 0s;
		z-index: 9;
	}

	radio .wx-radio-input,
	checkbox .wx-checkbox-input {
		margin: 0;
		width: 24rem;
		height: 24rem;
	}

	checkbox.round .wx-checkbox-input {
		border-radius: 100rem;
	}

	switch .wx-switch-input {
		border: none;
		padding: 0 24rem;
		width: 48rem;
		height: 26rem;
		margin: 0;
		border-radius: 100rem;
	}

	switch .wx-switch-input:not([class*="bg-"]) {
		background: var(--grey) !important;
	}

	switch .wx-switch-input::after {
		margin: auto;
		width: 26rem;
		height: 26rem;
		border-radius: 100rem;
		left: 0rem;
		top: 0rem;
		bottom: 0rem;
		position: absolute;
		transform: scale(0.9, 0.9);
		transition: all 0.1s ease-in-out 0s;
	}

	switch .wx-switch-input.wx-switch-input-checked::after {
		margin: auto;
		left: 22rem;
		box-shadow: none;
		transform: scale(0.9, 0.9);
	}

	radio-group {
		display: inline-block;
	}



	switch.radius .wx-switch-input::after,
	switch.radius .wx-switch-input,
	switch.radius .wx-switch-input::before {
		border-radius: 10rem;
	}

	switch .wx-switch-input::before,
	radio.radio::before,
	checkbox .wx-checkbox-input::before,
	radio .wx-radio-input::before,
	radio.radio::before {
		display: none;
	}

	radio.radio[checked]::after {
		content: "";
		background-color: transparent;
		display: block;
		position: absolute;
		width: 8rem;
		height: 8rem;
		z-index: 999;
		top: 0rem;
		left: 0rem;
		right: 0;
		bottom: 0;
		margin: auto;
		border-radius: 200rem;
		border: 8rem solid var(--white) !important;
	}

	.switch-sex::after {
		content: "\e71c";
	}

	.switch-sex::before {
		content: "\e71a";
	}

	.switch-sex .wx-switch-input {
		background: var(--red) !important;
		border-color: var(--red) !important;
	}

	.switch-sex[checked] .wx-switch-input {
		background: var(--blue) !important;
		border-color: var(--blue) !important;
	}

	switch.red[checked] .wx-switch-input,
	checkbox.red[checked] .wx-checkbox-input,
	radio.red[checked] .wx-radio-input {
		border-color: var(--red) !important;
	}

	switch.orange[checked] .wx-switch-input,
	checkbox.orange[checked] .wx-checkbox-input,
	radio.orange[checked] .wx-radio-input {
		border-color: var(--orange) !important;
	}

	switch.yellow[checked] .wx-switch-input,
	checkbox.yellow[checked] .wx-checkbox-input,
	radio.yellow[checked] .wx-radio-input {
		border-color: var(--yellow) !important;
	}

	switch.olive[checked] .wx-switch-input,
	checkbox.olive[checked] .wx-checkbox-input,
	radio.olive[checked] .wx-radio-input {
		border-color: var(--olive) !important;
	}

	switch.green[checked] .wx-switch-input,
	checkbox.green[checked] .wx-checkbox-input,
	checkbox[checked] .wx-checkbox-input,
	radio.green[checked] .wx-radio-input {
		border-color: var(--green) !important;
	}

	switch.cyan[checked] .wx-switch-input,
	checkbox.cyan[checked] .wx-checkbox-input,
	radio.cyan[checked] .wx-radio-input {
		border-color: var(--cyan) !important;
	}

	switch.blue[checked] .wx-switch-input,
	checkbox.blue[checked] .wx-checkbox-input,
	radio.blue[checked] .wx-radio-input {
		border-color: var(--blue) !important;
	}

	switch.purple[checked] .wx-switch-input,
	checkbox.purple[checked] .wx-checkbox-input,
	radio.purple[checked] .wx-radio-input {
		border-color: var(--purple) !important;
	}

	switch.mauve[checked] .wx-switch-input,
	checkbox.mauve[checked] .wx-checkbox-input,
	radio.mauve[checked] .wx-radio-input {
		border-color: var(--mauve) !important;
	}

	switch.pink[checked] .wx-switch-input,
	checkbox.pink[checked] .wx-checkbox-input,
	radio.pink[checked] .wx-radio-input {
		border-color: var(--pink) !important;
	}

	switch.brown[checked] .wx-switch-input,
	checkbox.brown[checked] .wx-checkbox-input,
	radio.brown[checked] .wx-radio-input {
		border-color: var(--brown) !important;
	}

	switch.grey[checked] .wx-switch-input,
	checkbox.grey[checked] .wx-checkbox-input,
	radio.grey[checked] .wx-radio-input {
		border-color: var(--grey) !important;
	}

	switch.gray[checked] .wx-switch-input,
	checkbox.gray[checked] .wx-checkbox-input,
	radio.gray[checked] .wx-radio-input {
		border-color: var(--grey) !important;
	}

	switch.black[checked] .wx-switch-input,
	checkbox.black[checked] .wx-checkbox-input,
	radio.black[checked] .wx-radio-input {
		border-color: var(--black) !important;
	}

	switch.white[checked] .wx-switch-input,
	checkbox.white[checked] .wx-checkbox-input,
	radio.white[checked] .wx-radio-input {
		border-color: var(--white) !important;
	}

	switch.red[checked] .wx-switch-input.wx-switch-input-checked,
	checkbox.red[checked] .wx-checkbox-input,
	radio.red[checked] .wx-radio-input {
		background-color: var(--red) !important;
		color: var(--white) !important;
	}

	switch.orange[checked] .wx-switch-input,
	checkbox.orange[checked] .wx-checkbox-input,
	radio.orange[checked] .wx-radio-input {
		background-color: var(--orange) !important;
		color: var(--white) !important;
	}

	switch.yellow[checked] .wx-switch-input,
	checkbox.yellow[checked] .wx-checkbox-input,
	radio.yellow[checked] .wx-radio-input {
		background-color: var(--yellow) !important;
		color: var(--black) !important;
	}

	switch.olive[checked] .wx-switch-input,
	checkbox.olive[checked] .wx-checkbox-input,
	radio.olive[checked] .wx-radio-input {
		background-color: var(--olive) !important;
		color: var(--white) !important;
	}

	switch.green[checked] .wx-switch-input,
	switch[checked] .wx-switch-input,
	checkbox.green[checked] .wx-checkbox-input,
	checkbox[checked] .wx-checkbox-input,
	radio.green[checked] .wx-radio-input,
	radio[checked] .wx-radio-input {
		background-color: var(--green) !important;
		color: var(--white) !important;
	}

	switch.cyan[checked] .wx-switch-input,
	checkbox.cyan[checked] .wx-checkbox-input,
	radio.cyan[checked] .wx-radio-input {
		background-color: var(--cyan) !important;
		color: var(--white) !important;
	}

	switch.blue[checked] .wx-switch-input,
	checkbox.blue[checked] .wx-checkbox-input,
	radio.blue[checked] .wx-radio-input {
		background-color: var(--blue) !important;
		color: var(--white) !important;
	}

	switch.purple[checked] .wx-switch-input,
	checkbox.purple[checked] .wx-checkbox-input,
	radio.purple[checked] .wx-radio-input {
		background-color: var(--purple) !important;
		color: var(--white) !important;
	}

	switch.mauve[checked] .wx-switch-input,
	checkbox.mauve[checked] .wx-checkbox-input,
	radio.mauve[checked] .wx-radio-input {
		background-color: var(--mauve) !important;
		color: var(--white) !important;
	}

	switch.pink[checked] .wx-switch-input,
	checkbox.pink[checked] .wx-checkbox-input,
	radio.pink[checked] .wx-radio-input {
		background-color: var(--pink) !important;
		color: var(--white) !important;
	}

	switch.brown[checked] .wx-switch-input,
	checkbox.brown[checked] .wx-checkbox-input,
	radio.brown[checked] .wx-radio-input {
		background-color: var(--brown) !important;
		color: var(--white) !important;
	}

	switch.grey[checked] .wx-switch-input,
	checkbox.grey[checked] .wx-checkbox-input,
	radio.grey[checked] .wx-radio-input {
		background-color: var(--grey) !important;
		color: var(--white) !important;
	}

	switch.gray[checked] .wx-switch-input,
	checkbox.gray[checked] .wx-checkbox-input,
	radio.gray[checked] .wx-radio-input {
		background-color: #f0f0f0 !important;
		color: var(--black) !important;
	}

	switch.black[checked] .wx-switch-input,
	checkbox.black[checked] .wx-checkbox-input,
	radio.black[checked] .wx-radio-input {
		background-color: var(--black) !important;
		color: var(--white) !important;
	}

	switch.white[checked] .wx-switch-input,
	checkbox.white[checked] .wx-checkbox-input,
	radio.white[checked] .wx-radio-input {
		background-color: var(--white) !important;
		color: var(--black) !important;
	}

	/* ==================
	          边框
	 ==================== */

	/* -- 实线 -- */

	.solid,
	.solid-top,
	.solid-right,
	.solid-bottom,
	.solid-left,
	.solids,
	.solids-top,
	.solids-right,
	.solids-bottom,
	.solids-left,
	.dashed,
	.dashed-top,
	.dashed-right,
	.dashed-bottom,
	.dashed-left {
		position: relative;
	}

	.solid::after,
	.solid-top::after,
	.solid-right::after,
	.solid-bottom::after,
	.solid-left::after,
	.solids::after,
	.solids-top::after,
	.solids-right::after,
	.solids-bottom::after,
	.solids-left::after,
	.dashed::after,
	.dashed-top::after,
	.dashed-right::after,
	.dashed-bottom::after,
	.dashed-left::after {
		content: " ";
		width: 200%;
		height: 200%;
		position: absolute;
		top: 0;
		left: 0;
		border-radius: inherit;
		transform: scale(0.5);
		transform-origin: 0 0;
		pointer-events: none;
		box-sizing: border-box;
	}

	.solid::after {
		border: 1rem solid rgba(0, 0, 0, 0.1);
	}

	.solid-top::after {
		border-top: 1rem solid rgba(0, 0, 0, 0.1);
	}

	.solid-right::after {
		border-right: 1rem solid rgba(0, 0, 0, 0.1);
	}

	.solid-bottom::after {
		border-bottom: 1rem solid rgba(0, 0, 0, 0.1);
	}

	.solid-left::after {
		border-left: 1rem solid rgba(0, 0, 0, 0.1);
	}

	.solids::after {
		border: 8rem solid #eee;
	}

	.solids-top::after {
		border-top: 8rem solid #eee;
	}

	.solids-right::after {
		border-right: 8rem solid #eee;
	}

	.solids-bottom::after {
		border-bottom: 8rem solid #eee;
	}

	.solids-left::after {
		border-left: 8rem solid #eee;
	}

	/* -- 虚线 -- */

	.dashed::after {
		border: 1rem dashed #ddd;
	}

	.dashed-top::after {
		border-top: 1rem dashed #ddd;
	}

	.dashed-right::after {
		border-right: 1rem dashed #ddd;
	}

	.dashed-bottom::after {
		border-bottom: 1rem dashed #ddd;
	}

	.dashed-left::after {
		border-left: 1rem dashed #ddd;
	}

	/* -- 阴影 -- */

	.shadow[class*='white'] {
		--ShadowSize: 0 1rem 6rem;
	}

	.shadow-lg {
		--ShadowSize: 0rem 40rem 100rem 0rem;
	}

	.shadow-warp {
		position: relative;
		box-shadow: 0 0 10rem rgba(0, 0, 0, 0.1);
	}

	.shadow-warp:before,
	.shadow-warp:after {
		position: absolute;
		content: "";
		top: 20rem;
		bottom: 30rem;
		left: 20rem;
		width: 50%;
		box-shadow: 0 30rem 20rem rgba(0, 0, 0, 0.2);
		transform: rotate(-3deg);
		z-index: -1;
	}

	.shadow-warp:after {
		right: 20rem;
		left: auto;
		transform: rotate(3deg);
	}

	.shadow-blur {
		position: relative;
	}

	.shadow-blur::before {
		content: "";
		display: block;
		background: inherit;
		filter: blur(10rem);
		position: absolute;
		width: 100%;
		height: 100%;
		top: 10rem;
		left: 10rem;
		z-index: -1;
		opacity: 0.4;
		transform-origin: 0 0;
		border-radius: inherit;
		transform: scale(1, 1);
	}

	/* ==================
	          按钮
	 ==================== */

	.cu-btn {
		position: relative;
		border: 0rem;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		box-sizing: border-box;
		padding: 0 30rem;
		font-size: 28rem;
		height: 64rem;
		line-height: 1;
		text-align: center;
		text-decoration: none;
		overflow: visible;
		margin-left: initial;
		transform: translate(0rem, 0rem);
		margin-right: initial;
	}

	.cu-btn::after {
		display: none;
	}

	.cu-btn:not([class*="bg-"]) {
		background-color: #f0f0f0;
	}

	.cu-btn[class*="line"] {
		background-color: transparent;
	}

	.cu-btn[class*="line"]::after {
		content: " ";
		display: block;
		width: 200%;
		height: 200%;
		position: absolute;
		top: 0;
		left: 0;
		border: 1rem solid currentColor;
		transform: scale(0.5);
		transform-origin: 0 0;
		box-sizing: border-box;
		border-radius: 12rem;
		z-index: 1;
		pointer-events: none;
	}

	.cu-btn.round[class*="line"]::after {
		border-radius: 1000rem;
	}

	.cu-btn[class*="lines"]::after {
		border: 6rem solid currentColor;
	}

	.cu-btn[class*="bg-"]::after {
		display: none;
	}

	.cu-btn.sm {
		padding: 0 20rem;
		font-size: 20rem;
		height: 48rem;
	}

	.cu-btn.lg {
		padding: 0 40rem;
		font-size: 32rem;
		height: 80rem;
	}

	.cu-btn.icon.sm {
		width: 48rem;
		height: 48rem;
	}

	.cu-btn.icon {
		width: 64rem;
		height: 64rem;
		border-radius: 500rem;
		padding: 0;
	}

	button.icon.lg {
		width: 80rem;
		height: 80rem;
	}

	.cu-btn.shadow-blur::before {
		top: 4rem;
		left: 4rem;
		filter: blur(6rem);
		opacity: 0.6;
	}

	.cu-btn.button-hover {
		transform: translate(1rem, 1rem);
	}

	.block {
		display: block;
	}

	.cu-btn.block {
		display: flex;
	}

	.cu-btn[disabled] {
		opacity: 0.6;
		color: var(--white);
	}

	/* ==================
	          徽章
	 ==================== */

	.cu-tag {
		font-size: 20rem;
		vertical-align: middle;
		position: relative;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		box-sizing: border-box;
		padding: 5rem 8rem;
		height: 30rem;
		line-height: 30rem;
		font-family: Helvetica Neue, Helvetica, sans-serif;
		white-space: nowrap;
	}

	.cu-tag:not([class*="bg"]):not([class*="line"]) {
		background-color: var(--ghostWhite);
	}

	.cu-tag[class*="line-"]::after {
		content: " ";
		width: 200%;
		height: 200%;
		position: absolute;
		top: 0;
		left: 0;
		border: 1rem solid currentColor;
		transform: scale(0.5);
		transform-origin: 0 0;
		box-sizing: border-box;
		border-radius: inherit;
		z-index: 1;
		pointer-events: none;
	}

	.cu-tag.radius[class*="line"]::after {
		border-radius: 12rem;
	}

	.cu-tag.round[class*="line"]::after {
		border-radius: 1000rem;
	}

	.cu-tag[class*="line-"]::after {
		border-radius: 0;
	}

	.cu-tag+.cu-tag {
		margin-left: 10rem;
	}

	.cu-tag.sm {
		font-size: 20rem;
		padding: 0rem 12rem;
		height: 32rem;
	}

	.cu-capsule {
		display: inline-flex;
		vertical-align: middle;
	}

	.cu-capsule+.cu-capsule {
		margin-left: 10rem;
	}

	.cu-capsule .cu-tag {
		margin: 0;
	}

	.cu-capsule .cu-tag[class*="line-"]:last-child::after {
		border-left: 0rem solid transparent;
	}

	.cu-capsule .cu-tag[class*="line-"]:first-child::after {
		border-right: 0rem solid transparent;
	}

	.cu-capsule.radius .cu-tag:first-child {
		border-top-left-radius: 6rem;
		border-bottom-left-radius: 6rem;
	}

	.cu-capsule.radius .cu-tag:last-child::after,
	.cu-capsule.radius .cu-tag[class*="line-"] {
		border-top-right-radius: 12rem;
		border-bottom-right-radius: 12rem;
	}

	.cu-capsule.round .cu-tag:first-child {
		border-top-left-radius: 200rem;
		border-bottom-left-radius: 200rem;
		text-indent: 4rem;
	}

	.cu-capsule.round .cu-tag:last-child::after,
	.cu-capsule.round .cu-tag:last-child {
		border-top-right-radius: 200rem;
		border-bottom-right-radius: 200rem;
		text-indent: -4rem;
	}

	.cu-tag.badge {
		border-radius: 200rem;
		position: absolute;
		top: -10rem;
		right: -10rem;
		font-size: 20rem;
		padding: 0rem 10rem;
		/* height: 28rem; */
		color: var(--white);
	}

	.cu-tag.badge:not([class*="bg-"]) {
		background-color: #dd514c;
	}

	.cu-tag:empty:not([class*="cuIcon-"]) {
		padding: 0rem;
		width: 16rem;
		height: 16rem;
		top: -4rem;
		right: -4rem;
	}

	.cu-tag[class*="cuIcon-"] {
		width: 32rem;
		height: 32rem;
		top: -4rem;
		right: -4rem;
	}

	/* ==================
	          头像
	 ==================== */

	.cu-avatar {
		font-variant: small-caps;
		margin: 0;
		padding: 0;
		display: inline-flex;
		text-align: center;
		justify-content: center;
		align-items: center;
		background-color: #ccc;
		color: var(--white);
		white-space: nowrap;
		position: relative;
		width: 64rem;
		height: 64rem;
		background-size: cover;
		background-position: center;
		vertical-align: middle;
		font-size: 1.5em;
	}

	.cu-avatar.sm {
		width: 48rem;
		height: 48rem;
		font-size: 1em;
	}

	.cu-avatar.lg {
		width: 96rem;
		height: 96rem;
		font-size: 2em;
	}

	.cu-avatar.xl {
		width: 128rem;
		height: 128rem;
		font-size: 2.5em;
	}

	.cu-avatar .avatar-text {
		font-size: 0.4em;
	}

	.cu-avatar-group {
		direction: rtl;
		unicode-bidi: bidi-override;
		padding: 0 10rem 0 40rem;
		display: inline-block;
	}

	.cu-avatar-group .cu-avatar {
		margin-left: -30rem;
		border: 4rem solid var(--ghostWhite);
		vertical-align: middle;
	}

	.cu-avatar-group .cu-avatar.sm {
		margin-left: -20rem;
		border: 1rem solid var(--ghostWhite);
	}

	/* ==================
	         进度条
	 ==================== */

	.cu-progress {
		overflow: hidden;
		height: 28rem;
		background-color: #ebeef5;
		display: inline-flex;
		align-items: center;
		width: 100%;
	}

	.cu-progress+view,
	.cu-progress+text {
		line-height: 1;
	}

	.cu-progress.xs {
		height: 10rem;
	}

	.cu-progress.sm {
		height: 20rem;
	}

	.cu-progress view {
		width: 0;
		height: 100%;
		align-items: center;
		display: flex;
		justify-items: flex-end;
		justify-content: space-around;
		font-size: 20rem;
		color: var(--white);
		transition: width 0.6s ease;
	}

	.cu-progress text {
		align-items: center;
		display: flex;
		font-size: 20rem;
		color: var(--black);
		text-indent: 10rem;
	}

	.cu-progress.text-progress {
		padding-right: 60rem;
	}

	.cu-progress.striped view {
		background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
		background-size: 72rem 72rem;
	}

	.cu-progress.active view {
		animation: progress-stripes 2s linear infinite;
	}

	@keyframes progress-stripes {
		from {
			background-position: 72rem 0;
		}

		to {
			background-position: 0 0;
		}
	}

	/* ==================
	          加载
	 ==================== */

	.cu-load {
		display: block;
		line-height: 3em;
		text-align: center;
	}

	.cu-load::before {
		font-family: "cuIcon";
		display: inline-block;
		margin-right: 6rem;
	}

	.cu-load.loading::before {
		content: "\e67a";
		animation: cuIcon-spin 2s infinite linear;
	}

	.cu-load.loading::after {
		content: "加载中...";
	}

	.cu-load.over::before {
		content: "\e64a";
	}

	.cu-load.over::after {
		content: "没有更多了";
	}

	.cu-load.erro::before {
		content: "\e658";
	}

	.cu-load.erro::after {
		content: "加载失败";
	}

	.cu-load.load-icon::before {
		font-size: 32rem;
	}

	.cu-load.load-icon::after {
		display: none;
	}

	.cu-load.load-icon.over {
		display: none;
	}

	.cu-load.load-modal {
		position: fixed;
		top: 0;
		right: 0;
		bottom: 140rem;
		left: 0;
		margin: auto;
		width: 260rem;
		height: 260rem;
		background-color: var(--white);
		border-radius: 10rem;
		box-shadow: 0 0 0rem 2000rem rgba(0, 0, 0, 0.5);
		display: flex;
		align-items: center;
		flex-direction: column;
		justify-content: center;
		font-size: 28rem;
		z-index: 9999;
		line-height: 2.4em;
	}

	.cu-load.load-modal [class*="cuIcon-"] {
		font-size: 60rem;
	}

	.cu-load.load-modal image {
		width: 70rem;
		height: 70rem;
	}

	.cu-load.load-modal::after {
		content: "";
		position: absolute;
		background-color: var(--white);
		border-radius: 50%;
		width: 200rem;
		height: 200rem;
		font-size: 10rem;
		border-top: 6rem solid rgba(0, 0, 0, 0.05);
		border-right: 6rem solid rgba(0, 0, 0, 0.05);
		border-bottom: 6rem solid rgba(0, 0, 0, 0.05);
		border-left: 6rem solid var(--orange);
		animation: cuIcon-spin 1s infinite linear;
		z-index: -1;
	}

	.load-progress {
		pointer-events: none;
		top: 0;
		position: fixed;
		width: 100%;
		left: 0;
		z-index: 2000;
	}

	.load-progress.hide {
		display: none;
	}

	.load-progress .load-progress-bar {
		position: relative;
		width: 100%;
		height: 4rem;
		overflow: hidden;
		transition: all 200ms ease 0s;
	}

	.load-progress .load-progress-spinner {
		position: absolute;
		top: 10rem;
		right: 10rem;
		z-index: 2000;
		display: block;
	}

	.load-progress .load-progress-spinner::after {
		content: "";
		display: block;
		width: 24rem;
		height: 24rem;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
		border: solid 4rem transparent;
		border-top-color: inherit;
		border-left-color: inherit;
		border-radius: 50%;
		-webkit-animation: load-progress-spinner 0.4s linear infinite;
		animation: load-progress-spinner 0.4s linear infinite;
	}

	@-webkit-keyframes load-progress-spinner {
		0% {
			-webkit-transform: rotate(0);
			transform: rotate(0);
		}

		100% {
			-webkit-transform: rotate(360deg);
			transform: rotate(360deg);
		}
	}

	@keyframes load-progress-spinner {
		0% {
			-webkit-transform: rotate(0);
			transform: rotate(0);
		}

		100% {
			-webkit-transform: rotate(360deg);
			transform: rotate(360deg);
		}
	}

	/* ==================
	          列表
	 ==================== */
	.grayscale {
		filter: grayscale(1);
	}

	.cu-list+.cu-list {
		margin-top: 30rem
	}

	.cu-list>.cu-item {
		transition: all .6s ease-in-out 0s;
		transform: translateX(0rem)
	}

	.cu-list>.cu-item.move-cur {
		transform: translateX(-260rem)
	}

	.cu-list>.cu-item .move {
		position: absolute;
		right: 0;
		display: flex;
		width: 260rem;
		height: 100%;
		transform: translateX(100%)
	}

	.cu-list>.cu-item .move view {
		display: flex;
		flex: 1;
		justify-content: center;
		align-items: center
	}

	.cu-list.menu-avatar {
		overflow: hidden;
	}

	.cu-list.menu-avatar>.cu-item {
		position: relative;
		display: flex;
		padding-right: 10rem;
		height: 140rem;
		background-color: var(--white);
		justify-content: flex-end;
		align-items: center
	}

	.cu-list.menu-avatar>.cu-item>.cu-avatar {
		position: absolute;
		left: 30rem
	}

	.cu-list.menu-avatar>.cu-item .flex .text-cut {
		max-width: 510rem
	}

	.cu-list.menu-avatar>.cu-item .content {
		position: absolute;
		left: 146rem;
		width: calc(100% - 96rem - 60rem - 120rem - 20rem);
		line-height: 1.6em;
	}

	.cu-list.menu-avatar>.cu-item .content.flex-sub {
		width: calc(100% - 96rem - 60rem - 20rem);
	}

	.cu-list.menu-avatar>.cu-item .content>view:first-child {
		font-size: 30rem;
		display: flex;
		align-items: center
	}

	.cu-list.menu-avatar>.cu-item .content .cu-tag.sm {
		display: inline-block;
		margin-left: 10rem;
		height: 28rem;
		font-size: 16rem;
		line-height: 32rem
	}

	.cu-list.menu-avatar>.cu-item .action {
		/* width: 100rem; */
		text-align: center
	}

	.cu-list.menu-avatar>.cu-item .action view+view {
		margin-top: 10rem
	}

	.cu-list.menu-avatar.comment>.cu-item .content {
		position: relative;
		left: 0;
		width: auto;
		flex: 1;
	}

	.cu-list.menu-avatar.comment>.cu-item {
		padding: 30rem 30rem 30rem 120rem;
		height: auto
	}

	.cu-list.menu-avatar.comment .cu-avatar {
		align-self: flex-start
	}

	.cu-list.menu>.cu-item {
		position: relative;
		display: flex;
		padding: 0 30rem;
		min-height: 100rem;
		background-color: var(--white);
		justify-content: space-between;
		align-items: center
	}

	.cu-list.menu>.cu-item:last-child:after {
		border: none
	}

	.cu-list.menu>.cu-item:after {
		position: absolute;
		top: 0;
		left: 0;
		box-sizing: border-box;
		width: 200%;
		height: 200%;
		border-bottom: 1rem solid #ddd;
		border-radius: inherit;
		content: " ";
		transform: scale(.5);
		transform-origin: 0 0;
		pointer-events: none
	}

	.cu-list.menu>.cu-item.grayscale {
		background-color: #f5f5f5
	}

	.cu-list.menu>.cu-item.cur {
		background-color: #fcf7e9
	}

	.cu-list.menu>.cu-item.arrow {
		padding-right: 90rem
	}

	.cu-list.menu>.cu-item.arrow:before {
		position: absolute;
		top: 0;
		right: 30rem;
		bottom: 0;
		display: block;
		margin: auto;
		width: 30rem;
		height: 30rem;
		color: var(--grey);
		content: "\e6a3";
		text-align: center;
		font-size: 34rem;
		font-family: "cuIcon";
		line-height: 30rem
	}

	.cu-list.menu>.cu-item button.content {
		padding: 0;
		background-color: transparent;
		justify-content: flex-start
	}

	.cu-list.menu>.cu-item button.content:after {
		display: none
	}

	.cu-list.menu>.cu-item .cu-avatar-group .cu-avatar {
		border-color: var(--white)
	}

	.cu-list.menu>.cu-item .content>view:first-child {
		display: flex;
		align-items: center
	}

	.cu-list.menu>.cu-item .content>text[class*=cuIcon] {
		display: inline-block;
		margin-right: 10rem;
		width: 1.6em;
		text-align: center
	}

	.cu-list.menu>.cu-item .content>image {
		display: inline-block;
		margin-right: 10rem;
		width: 1.6em;
		height: 1.6em;
		vertical-align: middle
	}

	.cu-list.menu>.cu-item .content {
		font-size: 30rem;
		line-height: 1.6em;
		flex: 1
	}

	.cu-list.menu>.cu-item .value {
		font-size: 30rem;
		line-height: 1.6em;
		/* flex: 1; */
		display: flex;
		justify-content: flex-end;
		color: var(--grey);
	}

	.cu-list.menu>.cu-item .content .cu-tag.sm {
		display: inline-block;
		margin-left: 10rem;
		height: 28rem;
		font-size: 16rem;
		line-height: 32rem
	}

	.cu-list.menu>.cu-item .action .cu-tag:empty {
		right: 10rem
	}

	.cu-list.menu {
		display: block;
		overflow: hidden
	}

	.cu-list.menu.sm-border>.cu-item:after {
		left: 30rem;
		width: calc(200% - 120rem)
	}

	.cu-list.grid>.cu-item {
		position: relative;
		display: flex;
		padding: 20rem 0 30rem;
		transition-duration: 0s;
		flex-direction: column
	}

	.cu-list.grid>.cu-item:after {
		position: absolute;
		top: 0;
		left: 0;
		box-sizing: border-box;
		width: 200%;
		height: 200%;
		border-right: 1rem solid rgba(0, 0, 0, .1);
		border-bottom: 1rem solid rgba(0, 0, 0, .1);
		border-radius: inherit;
		content: " ";
		transform: scale(.5);
		transform-origin: 0 0;
		pointer-events: none
	}

	.cu-list.grid>.cu-item text {
		display: block;
		margin-top: 10rem;
		color: #888;
		font-size: 26rem;
		line-height: 40rem
	}

	.cu-list.grid>.cu-item [class*=cuIcon] {
		position: relative;
		display: block;
		margin-top: 20rem;
		width: 100%;
		font-size: 48rem
	}

	.cu-list.grid>.cu-item .cu-tag {
		right: auto;
		left: 50%;
		margin-left: 20rem
	}

	.cu-list.grid {
		background-color: var(--white);
		text-align: center
	}

	.cu-list.grid.no-border>.cu-item {
		padding-top: 10rem;
		padding-bottom: 20rem
	}

	.cu-list.grid.no-border>.cu-item:after {
		border: none
	}

	.cu-list.grid.no-border {
		padding: 20rem 10rem
	}

	.cu-list.grid.col-3>.cu-item:nth-child(3n):after,
	.cu-list.grid.col-4>.cu-item:nth-child(4n):after,
	.cu-list.grid.col-5>.cu-item:nth-child(5n):after {
		border-right-width: 0
	}

	.cu-list.card-menu {
		overflow: hidden;
		margin-right: 30rem;
		margin-left: 30rem;
		border-radius: 20rem
	}


	/* ==================
	          操作条
	 ==================== */

	.cu-bar {
		display: flex;
		position: relative;
		align-items: center;
		min-height: 100rem;
		justify-content: space-between;
	}

	.cu-bar .action {
		display: flex;
		align-items: center;
		height: 100%;
		justify-content: center;
		max-width: 100%;
	}

	.cu-bar .action.border-title {
		position: relative;
		top: -10rem;
	}

	.cu-bar .action.border-title text[class*="bg-"]:last-child {
		position: absolute;
		bottom: -0.5rem;
		min-width: 2rem;
		height: 6rem;
		left: 0;
	}

	.cu-bar .action.sub-title {
		position: relative;
		top: -0.2rem;
	}

	.cu-bar .action.sub-title text {
		position: relative;
		z-index: 1;
	}

	.cu-bar .action.sub-title text[class*="bg-"]:last-child {
		position: absolute;
		display: inline-block;
		bottom: -0.2rem;
		border-radius: 6rem;
		width: 100%;
		height: 0.6rem;
		left: 0.6rem;
		opacity: 0.3;
		z-index: 0;
	}

	.cu-bar .action.sub-title text[class*="text-"]:last-child {
		position: absolute;
		display: inline-block;
		bottom: -0.7rem;
		left: 0.5rem;
		opacity: 0.2;
		z-index: 0;
		text-align: right;
		font-weight: 900;
		font-size: 36rem;
	}

	.cu-bar.justify-center .action.border-title text:last-child,
	.cu-bar.justify-center .action.sub-title text:last-child {
		left: 0;
		right: 0;
		margin: auto;
		text-align: center;
	}

	.cu-bar .action:first-child {
		margin-left: 30rem;
		font-size: 30rem;
	}

	.cu-bar .action text.text-cut {
		text-align: left;
		width: 100%;
	}

	.cu-bar .cu-avatar:first-child {
		margin-left: 20rem;
	}

	.cu-bar .action:first-child>text[class*="cuIcon-"] {
		margin-left: -0.3em;
		margin-right: 0.3em;
	}

	.cu-bar .action:last-child {
		margin-right: 30rem;
	}

	.cu-bar .action>text[class*="cuIcon-"],
	.cu-bar .action>view[class*="cuIcon-"] {
		font-size: 36rem;
	}

	.cu-bar .action>text[class*="cuIcon-"]+text[class*="cuIcon-"] {
		margin-left: 0.5em;
	}

	.cu-bar .content {
		position: absolute;
		text-align: center;
		width: calc(100% - 340rem);
		left: 0;
		right: 0;
		bottom: 0;
		top: 0;
		margin: auto;
		height: 60rem;
		font-size: 32rem;
		line-height: 60rem;
		cursor: none;
		pointer-events: none;
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
	}

	.cu-bar.ios .content {
		bottom: 7rem;
		height: 30rem;
		font-size: 32rem;
		line-height: 30rem;
	}

	.cu-bar.btn-group {
		justify-content: space-around;
	}

	.cu-bar.btn-group button {
		padding: 20rem 32rem;
	}

	.cu-bar.btn-group button {
		flex: 1;
		margin: 0 20rem;
		max-width: 50%;
	}

	.cu-bar .search-form {
		background-color: #f5f5f5;
		line-height: 64rem;
		height: 64rem;
		font-size: 24rem;
		color: var(--black);
		flex: 1;
		display: flex;
		align-items: center;
		margin: 0 30rem;
	}

	.cu-bar .search-form+.action {
		margin-right: 30rem;
	}

	.cu-bar .search-form input {
		flex: 1;
		/* padding-right: 30rem; */
		height: 64rem;
		line-height: 64rem;
		font-size: 26rem;
		background-color: transparent;
	}

	.cu-bar .search-form [class*="cuIcon-"] {
		margin: 0 0.5em 0 0.8em;
	}

	.cu-bar .search-form [class*="cuIcon-"]::before {
		top: 0rem;
	}

	.cu-bar.fixed,
	.nav.fixed {
		position: fixed;
		width: 100%;
		top: 0;
		z-index: 1024;
		box-shadow: 0 1rem 6rem rgba(0, 0, 0, 0.1);
	}

	.cu-bar.foot {
		position: fixed;
		width: 100%;
		bottom: 0;
		z-index: 1024;
		box-shadow: 0 -1rem 6rem rgba(0, 0, 0, 0.1);
	}

	.cu-bar.tabbar {
		padding: 0;
		height: calc(100rem + env(safe-area-inset-bottom) / 2);
		padding-bottom: calc(env(safe-area-inset-bottom) / 2);
	}

	.cu-tabbar-height {
		min-height: 100rem;
		height: calc(100rem + env(safe-area-inset-bottom) / 2);
	}

	.cu-bar.tabbar.shadow {
		box-shadow: 0 -1rem 6rem rgba(0, 0, 0, 0.1);
	}

	.cu-bar.tabbar .action {
		font-size: 22rem;
		position: relative;
		flex: 1;
		text-align: center;
		padding: 0;
		display: block;
		height: auto;
		line-height: 1;
		margin: 0;
		overflow: initial;
	}

	.cu-bar.tabbar.shop .action {
		width: 140rem;
		flex: initial;
	}

	.cu-bar.tabbar .action.add-action {
		position: relative;
		z-index: 2;
		padding-top: 50rem;
		background-color: inherit;
	}

	.cu-bar.tabbar .action.add-action [class*="cuIcon-"] {
		position: absolute;
		width: 70rem;
		z-index: 2;
		height: 70rem;
		border-radius: 50%;
		line-height: 70rem;
		font-size: 50rem;
		top: -35rem;
		left: 0;
		right: 0;
		margin: auto;
		padding: 0;
	}

	.cu-bar.tabbar .action.add-action::after {
		content: "";
		position: absolute;
		width: 100rem;
		height: 100rem;
		top: -50rem;
		left: 0;
		right: 0;
		margin: auto;
		box-shadow: 0 -3rem 8rem rgba(0, 0, 0, 0.08);
		border-radius: 50rem;
		background-color: inherit;
		z-index: 0;
	}

	.cu-bar.tabbar .action.add-action::before {
		content: "";
		position: absolute;
		width: 100rem;
		height: 30rem;
		bottom: 30rem;
		left: 0;
		right: 0;
		margin: auto;
		background-color: inherit;
		z-index: 1;
	}

	.cu-bar.tabbar .btn-group {
		flex: 1;
		display: flex;
		justify-content: space-around;
		align-items: center;
		padding: 0 10rem;
	}

	.cu-bar.tabbar button.action::after {
		border: 0;
	}

	.cu-bar.tabbar .action [class*="cuIcon-"] {
		width: 100rem;
		position: relative;
		display: block;
		height: auto;
		margin: 0 auto 10rem;
		text-align: center;
		font-size: 40rem;
	}

	.cu-bar.tabbar .action .cuIcon-cu-image {
		margin: 0 auto;
	}

	.cu-bar.tabbar .action .cuIcon-cu-image image {
		width: 50rem;
		height: 50rem;
		display: inline-block;
	}

	.cu-bar.tabbar .submit {
		align-items: center;
		display: flex;
		justify-content: center;
		text-align: center;
		position: relative;
		flex: 2;
		align-self: stretch;
	}

	.cu-bar.tabbar .submit:last-child {
		flex: 2.6;
	}

	.cu-bar.tabbar .submit+.submit {
		flex: 2;
	}

	.cu-bar.tabbar.border .action::before {
		content: " ";
		width: 200%;
		height: 200%;
		position: absolute;
		top: 0;
		left: 0;
		transform: scale(0.5);
		transform-origin: 0 0;
		border-right: 1rem solid rgba(0, 0, 0, 0.1);
		z-index: 3;
	}

	.cu-bar.tabbar.border .action:last-child:before {
		display: none;
	}

	.cu-bar.input {
		padding-right: 20rem;
		background-color: var(--white);
	}

	.cu-bar.input input {
		overflow: initial;
		line-height: 64rem;
		height: 64rem;
		min-height: 64rem;
		flex: 1;
		font-size: 30rem;
		margin: 0 20rem;
	}

	.cu-bar.input .action {
		margin-left: 20rem;
	}

	.cu-bar.input .action [class*="cuIcon-"] {
		font-size: 48rem;
	}

	.cu-bar.input input+.action {
		margin-right: 20rem;
		margin-left: 0rem;
	}

	.cu-bar.input .action:first-child [class*="cuIcon-"] {
		margin-left: 0rem;
	}

	.cu-custom {
		display: block;
		position: relative;
	}

	.cu-custom .cu-bar .content {
		width: calc(100% - 440rem);
	}


	.cu-custom .cu-bar .content image {
		height: 60rem;
		width: 240rem;
	}

	.cu-custom .cu-bar {
		min-height: 0rem;
		padding-right: 220rem;
		box-shadow: 0rem 0rem 0rem;
		z-index: 9999;
	}

	.cu-custom .cu-bar .border-custom {
		position: relative;
		background: rgba(0, 0, 0, 0.15);
		border-radius: 1000rem;
		height: 30rem;
	}

	.cu-custom .cu-bar .border-custom::after {
		content: " ";
		width: 200%;
		height: 200%;
		position: absolute;
		top: 0;
		left: 0;
		border-radius: inherit;
		transform: scale(0.5);
		transform-origin: 0 0;
		pointer-events: none;
		box-sizing: border-box;
		border: 1rem solid var(--white);
		opacity: 0.5;
	}

	.cu-custom .cu-bar .border-custom::before {
		content: " ";
		width: 1rem;
		height: 110%;
		position: absolute;
		top: 22.5%;
		left: 0;
		right: 0;
		margin: auto;
		transform: scale(0.5);
		transform-origin: 0 0;
		pointer-events: none;
		box-sizing: border-box;
		opacity: 0.6;
		background-color: var(--white);
	}

	.cu-custom .cu-bar .border-custom text {
		display: block;
		flex: 1;
		margin: auto !important;
		text-align: center;
		font-size: 34rem;
	}

	/* ==================
	         导航栏
	 ==================== */

	.nav {
		white-space: nowrap;
	}

	::-webkit-scrollbar {
		display: none;
	}

	.nav .cu-item {
		height: 90rem;
		display: inline-block;
		line-height: 90rem;
		margin: 0 10rem;
		padding: 0 20rem;
	}

	.nav .cu-item.cur {
		border-bottom: 4rem solid;
	}

	/* ==================
	         时间轴
	 ==================== */

	.cu-timeline {
		display: block;
		background-color: var(--white);
	}

	.cu-timeline .cu-time {
		width: 120rem;
		text-align: center;
		padding: 20rem 0;
		font-size: 26rem;
		color: #888;
		display: block;
	}

	.cu-timeline>.cu-item {
		padding: 30rem 30rem 30rem 120rem;
		position: relative;
		display: block;
		z-index: 0;
	}

	.cu-timeline>.cu-item:not([class*="text-"]) {
		color: #ccc;
	}

	.cu-timeline>.cu-item::after {
		content: "";
		display: block;
		position: absolute;
		width: 1rem;
		background-color: #ddd;
		left: 60rem;
		height: 100%;
		top: 0;
		z-index: 8;
	}

	.cu-timeline>.cu-item::before {
		font-family: "cuIcon";
		display: block;
		position: absolute;
		top: 36rem;
		z-index: 9;
		background-color: var(--white);
		width: 50rem;
		height: 50rem;
		text-align: center;
		border: none;
		line-height: 50rem;
		left: 36rem;
	}

	.cu-timeline>.cu-item:not([class*="cuIcon-"])::before {
		content: "\e763";
	}

	.cu-timeline>.cu-item[class*="cuIcon-"]::before {
		background-color: var(--white);
		width: 50rem;
		height: 50rem;
		text-align: center;
		border: none;
		line-height: 50rem;
		left: 36rem;
	}

	.cu-timeline>.cu-item>.content {
		padding: 30rem;
		border-radius: 6rem;
		display: block;
		line-height: 1.6;
	}

	.cu-timeline>.cu-item>.content:not([class*="bg-"]) {
		background-color: var(--ghostWhite);
		color: var(--black);
	}

	.cu-timeline>.cu-item>.content+.content {
		margin-top: 20rem;
	}

	/* ==================
	         聊天
	 ==================== */

	.cu-chat {
		display: flex;
		flex-direction: column;
	}

	.cu-chat .cu-item {
		display: flex;
		padding: 20rem 20rem 0 20rem;
		position: relative;
	}

	.cu-chat .cu-item>.cu-avatar {
		width: 80rem;
		height: 80rem;
	}

	.cu-chat .cu-item>.main {
		max-width: calc(100% - 200rem);
		margin: 25rem 20rem 0 20rem;
		display: flex;
		align-items: center;
	}

	.cu-chat .cu-item>image {
		height: 320rem;
	}

	.cu-chat .cu-item>.main .content {
		padding: 10rem;
		border-radius: 6rem;
		display: inline-flex;
		max-width: 100%;
		align-items: center;
		font-size: 28rem;
		position: relative;
		min-height: 80rem;
		line-height: 40rem;
		text-align: left;
	}

	.cu-chat .cu-item>.main .content:not([class*="bg-"]) {
		background-color: var(--white);
		color: var(--black);
	}

	.cu-chat .cu-item .date {
		position: absolute;
		font-size: 22rem;
		color: var(--grey);
		width: calc(100% - 320rem);
		top: 20rem;
		/* left: 140rem; */
		height: 22rem;
		line-height: 22rem;
	}

	.cu-chat .cu-item .action {
		padding: 0 30rem;
		display: flex;
		align-items: center;
	}

	/* .cu-chat .cu-item>.main .content::after {
		content: "";
		top: 27rem;
		transform: rotate(45deg);
		position: absolute;
		z-index: 100;
		display: inline-block;
		overflow: hidden;
		width: 24rem;
		height: 24rem;
		left: -12rem;
		right: initial;
		background-color: inherit;
	} */
	/* 
	.cu-chat .cu-item.self>.main .content::after {
		left: auto;
		right: -12rem;
	}
	
	.cu-chat .cu-item>.main .content::before {
		content: "";
		top: 30rem;
		transform: rotate(45deg);
		position: absolute;
		z-index: -1;
		display: inline-block;
		overflow: hidden;
		width: 24rem;
		height: 24rem;
		left: -12rem;
		right: initial;
		background-color: inherit;
		filter: blur(5rem);
		opacity: 0.3;
	} */

	.cu-chat .cu-item>.main .content:not([class*="bg-"])::before {
		background-color: var(--black);
		opacity: 0.1;
	}

	/* .cu-chat .cu-item.self>.main .content::before {
		left: auto;
		right: -12rem;
	} */

	.cu-chat .cu-item.self {
		justify-content: flex-end;
		text-align: right;
	}

	.cu-chat .cu-info {
		display: inline-block;
		margin: 20rem auto;
		font-size: 24rem;
		padding: 8rem 12rem;
		background-color: rgba(0, 0, 0, 0.2);
		border-radius: 6rem;
		color: var(--white);
		max-width: 400rem;
		line-height: 1.4;
	}

	/* ==================
	         卡片
	 ==================== */

	.cu-card {
		display: block;
		overflow: hidden;
	}

	.cu-card>.cu-item {
		display: block;
		background-color: var(--white);
		overflow: hidden;
		border-radius: 10rem;
		margin: 30rem;
	}

	.cu-card>.cu-item.shadow-blur {
		overflow: initial;
	}

	.cu-card.no-card>.cu-item {
		margin: 0rem;
		border-radius: 0rem;
	}

	.cu-card .grid.grid-square {
		margin-bottom: -20rem;
	}

	.cu-card.case .image {
		position: relative;
	}

	.cu-card.case .image image {
		width: 100%;
	}

	.cu-card.case .image .cu-tag {
		position: absolute;
		right: 0;
		top: 0;
	}

	.cu-card.case .image .cu-bar {
		position: absolute;
		bottom: 0;
		width: 100%;
		background-color: transparent;
		padding: 0rem 30rem;
	}

	.cu-card.case.no-card .image {
		margin: 30rem 30rem 0;
		overflow: hidden;
		border-radius: 10rem;
	}

	.cu-card.dynamic {
		display: block;
	}

	.cu-card.dynamic>.cu-item {
		display: block;
		background-color: var(--white);
		overflow: hidden;
	}

	.cu-card.dynamic>.cu-item>.text-content {
		padding: 0 30rem 0;
		max-height: 6.4em;
		overflow: hidden;
		font-size: 30rem;
		margin-bottom: 20rem;
	}

	.cu-card.dynamic>.cu-item .square-img {
		width: 100%;
		height: 200rem;
		border-radius: 6rem;
	}

	.cu-card.dynamic>.cu-item .only-img {
		width: 100%;
		height: 320rem;
		border-radius: 6rem;
	}

	.cu-card.article {
		display: block;
	}

	.cu-card.article>.cu-item {
		padding-bottom: 30rem;
	}

	.cu-card.article>.cu-item .title {
		font-size: 30rem;
		font-weight: 900;
		color: var(--black);
		line-height: 100rem;
		padding: 0 30rem;
	}

	.cu-card.article>.cu-item .content {
		display: flex;
		padding: 0 30rem;
	}

	.cu-card.article>.cu-item .content>image {
		width: 240rem;
		height: 6.4em;
		margin-right: 20rem;
		border-radius: 6rem;
	}

	.cu-card.article>.cu-item .content .desc {
		flex: 1;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}

	.cu-card.article>.cu-item .content .text-content {
		font-size: 28rem;
		color: #888;
		height: 4.8em;
		overflow: hidden;
	}

	/* ==================
	         表单
	 ==================== */

	.cu-form-group {
		background-color: var(--white);
		padding: 1rem 30rem;
		display: flex;
		align-items: center;
		min-height: 100rem;
		justify-content: space-between;
		position: relative;

	}

	.cu-form-group.arrow {
		padding-right: 90rem
	}

	.cu-form-group.noarrow {
		padding-right: 90rem
	}

	.cu-form-group.arrow:before {
		position: absolute;
		top: 0;
		right: 30rem;
		bottom: 0;
		display: block;
		margin: auto;
		width: 30rem;
		height: 30rem;
		color: var(--grey);
		content: "\e6a3";
		text-align: center;
		font-size: 34rem;
		font-family: "cuIcon";
		line-height: 30rem
	}

	.cu-form-group+.cu-form-group {
		border-top: 1rem solid #eee;
	}

	.cu-form-group .title {
		text-align: justify;
		padding-right: 30rem;
		font-size: 30rem;
		position: relative;
		height: 60rem;
		line-height: 60rem;
	}

	.cu-form-group .value {
		font-size: 30rem;
		line-height: 1.6em;
		/* flex: 1; */
		display: flex;
		justify-content: flex-end;
		color: var(--grey);
	}

	.cu-form-group input {
		flex: 1;
		font-size: 30rem;
		color: #555;
		padding-right: 20rem;
	}

	.cu-form-group>text[class*="cuIcon-"] {
		font-size: 36rem;
		padding: 0;
		box-sizing: border-box;
	}

	.cu-form-group textarea {
		margin: 32rem 0 30rem;
		height: 4.6em;
		width: 100%;
		line-height: 1.2em;
		flex: 1;
		font-size: 28rem;
		padding: 0;
	}

	.cu-form-group.align-start .title {
		height: 1em;
		margin-top: 32rem;
		line-height: 1em;
	}

	.cu-form-group picker {
		flex: 1;
		padding-right: 40rem;
		overflow: hidden;
		position: relative;
	}

	.cu-form-group picker .picker {
		line-height: 100rem;
		font-size: 28rem;
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
		width: 100%;
		text-align: right;
	}

	.cu-form-group picker::after {
		font-family: "cuIcon";
		display: block;
		content: "\e6a3";
		position: absolute;
		font-size: 34rem;
		color: var(--grey);
		line-height: 100rem;
		width: 60rem;
		text-align: center;
		top: 0;
		bottom: 0;
		right: -20rem;
		margin: auto;
	}

	.cu-form-group textarea[disabled],
	.cu-form-group textarea[disabled] .placeholder {
		color: transparent;
	}

	/* ==================
	         模态窗口
	 ==================== */

	.cu-modal {
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 1110;
		opacity: 0;
		outline: 0;
		text-align: center;
		-ms-transform: scale(1.185);
		transform: scale(1.185);
		backface-visibility: hidden;
		perspective: 2000rem;
		background: rgba(0, 0, 0, 0.6);
		transition: all 0.3s ease-in-out 0s;
		pointer-events: none;
	}

	.cu-modal::before {
		content: "\200B";
		display: inline-block;
		height: 100%;
		vertical-align: middle;
	}

	.cu-modal.show {
		opacity: 1;
		transition-duration: 0.3s;
		-ms-transform: scale(1);
		transform: scale(1);
		overflow-x: hidden;
		overflow-y: auto;
		pointer-events: auto;
	}

	.cu-dialog {
		position: relative;
		display: inline-block;
		vertical-align: middle;
		margin-left: auto;
		margin-right: auto;
		width: 680rem;
		max-width: 100%;
		background-color: #f8f8f8;
		border-radius: 10rem;
		overflow: hidden;
	}

	.cu-modal.bottom-modal::before {
		vertical-align: bottom;
	}

	.cu-modal.bottom-modal .cu-dialog {
		width: 100%;
		border-radius: 0;
	}

	.cu-modal.bottom-modal {
		margin-bottom: -1000rem;
	}

	.cu-modal.bottom-modal.show {
		margin-bottom: 0;
	}

	.cu-modal.drawer-modal {
		transform: scale(1);
		display: flex;
	}

	.cu-modal.drawer-modal .cu-dialog {
		height: 100%;
		min-width: 200rem;
		border-radius: 0;
		margin: initial;
		transition-duration: 0.3s;
	}

	.cu-modal.drawer-modal.justify-start .cu-dialog {
		transform: translateX(-100%);
	}

	.cu-modal.drawer-modal.justify-end .cu-dialog {
		transform: translateX(100%);
	}

	.cu-modal.drawer-modal.show .cu-dialog {
		transform: translateX(0%);
	}

	.cu-modal .cu-dialog>.cu-bar:first-child .action {
		min-width: 100rem;
		margin-right: 0;
		min-height: 100rem;
	}

	/* ==================
	         轮播
	 ==================== */
	swiper .a-swiper-dot {
		display: inline-block;
		width: 16rem;
		height: 16rem;
		background: rgba(0, 0, 0, .3);
		border-radius: 50%;
		vertical-align: middle;
	}

	swiper[class*="-dot"] .wx-swiper-dots {
		display: flex;
		align-items: center;
		width: 100%;
		justify-content: center;
	}

	swiper.square-dot .wx-swiper-dot {
		background-color: var(--white);
		opacity: 0.4;
		width: 10rem;
		height: 10rem;
		border-radius: 20rem;
		margin: 0 8rem !important;
	}

	swiper.square-dot .wx-swiper-dot.wx-swiper-dot-active {
		opacity: 1;
		width: 30rem;
	}

	swiper.round-dot .wx-swiper-dot {
		width: 10rem;
		height: 10rem;
		position: relative;
		margin: 4rem 8rem !important;
	}

	swiper.round-dot .wx-swiper-dot.wx-swiper-dot-active::after {
		content: "";
		position: absolute;
		width: 10rem;
		height: 10rem;
		top: 0rem;
		left: 0rem;
		right: 0;
		bottom: 0;
		margin: auto;
		background-color: var(--white);
		border-radius: 20rem;
	}

	swiper.round-dot .wx-swiper-dot.wx-swiper-dot-active {
		width: 18rem;
		height: 18rem;
	}

	.screen-swiper {
		min-height: 375rem;
	}

	.screen-swiper image,
	.screen-swiper video,
	.swiper-item image,
	.swiper-item video {
		width: 100%;
		display: block;
		height: 100%;
		margin: 0;
		pointer-events: none;
	}

	.card-swiper {
		height: 420rem !important;
	}

	.card-swiper swiper-item {
		width: 610rem !important;
		left: 70rem;
		box-sizing: border-box;
		padding: 40rem 0rem 70rem;
		overflow: initial;
	}

	.card-swiper swiper-item .swiper-item {
		width: 100%;
		display: block;
		height: 100%;
		border-radius: 10rem;
		transform: scale(0.9);
		transition: all 0.2s ease-in 0s;
		overflow: hidden;
	}

	.card-swiper swiper-item.cur .swiper-item {
		transform: none;
		transition: all 0.2s ease-in 0s;
	}


	.tower-swiper {
		height: 420rem;
		position: relative;
		max-width: 750rem;
		overflow: hidden;
	}

	.tower-swiper .tower-item {
		position: absolute;
		width: 300rem;
		height: 380rem;
		top: 0;
		bottom: 0;
		left: 50%;
		margin: auto;
		transition: all 0.2s ease-in 0s;
		opacity: 1;
	}

	.tower-swiper .tower-item.none {
		opacity: 0;
	}

	.tower-swiper .tower-item .swiper-item {
		width: 100%;
		height: 100%;
		border-radius: 6rem;
		overflow: hidden;
	}

	/* ==================
	          步骤条
	 ==================== */

	.cu-steps {
		display: flex;
	}

	scroll-view.cu-steps {
		display: block;
		white-space: nowrap;
	}

	scroll-view.cu-steps .cu-item {
		display: inline-block;
	}

	.cu-steps .cu-item {
		flex: 1;
		text-align: center;
		position: relative;
		min-width: 100rem;
	}

	.cu-steps .cu-item:not([class*="text-"]) {
		color: var(--grey);
	}

	.cu-steps .cu-item [class*="cuIcon-"],
	.cu-steps .cu-item .num {
		display: block;
		font-size: 40rem;
		line-height: 80rem;
	}

	.cu-steps .cu-item::before,
	.cu-steps .cu-item::after,
	.cu-steps.steps-arrow .cu-item::before,
	.cu-steps.steps-arrow .cu-item::after {
		content: "";
		display: block;
		position: absolute;
		height: 0rem;
		width: calc(100% - 80rem);
		border-bottom: 1rem solid #ccc;
		left: calc(0rem - (100% - 80rem) / 2);
		top: 40rem;
		z-index: 0;
	}

	.cu-steps.steps-arrow .cu-item::before,
	.cu-steps.steps-arrow .cu-item::after {
		content: "\e6a3";
		font-family: "cuIcon";
		height: 30rem;
		border-bottom-width: 0rem;
		line-height: 30rem;
		top: 0;
		bottom: 0;
		margin: auto;
		color: #ccc;
	}

	.cu-steps.steps-bottom .cu-item::before,
	.cu-steps.steps-bottom .cu-item::after {
		bottom: 40rem;
		top: initial;
	}

	.cu-steps .cu-item::after {
		border-bottom: 1rem solid currentColor;
		width: 0rem;
		transition: all 0.3s ease-in-out 0s;
	}

	.cu-steps .cu-item[class*="text-"]::after {
		width: calc(100% - 80rem);
		color: currentColor;
	}

	.cu-steps .cu-item:first-child::before,
	.cu-steps .cu-item:first-child::after {
		display: none;
	}

	.cu-steps .cu-item .num {
		width: 40rem;
		height: 40rem;
		border-radius: 50%;
		line-height: 40rem;
		margin: 20rem auto;
		font-size: 24rem;
		border: 1rem solid currentColor;
		position: relative;
		overflow: hidden;
	}

	.cu-steps .cu-item[class*="text-"] .num {
		background-color: currentColor;
	}

	.cu-steps .cu-item .num::before,
	.cu-steps .cu-item .num::after {
		content: attr(data-index);
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		margin: auto;
		transition: all 0.3s ease-in-out 0s;
		transform: translateY(0rem);
	}

	.cu-steps .cu-item[class*="text-"] .num::before {
		transform: translateY(-40rem);
		color: var(--white);
	}

	.cu-steps .cu-item .num::after {
		transform: translateY(40rem);
		color: var(--white);
		transition: all 0.3s ease-in-out 0s;
	}

	.cu-steps .cu-item[class*="text-"] .num::after {
		content: "\e645";
		font-family: "cuIcon";
		color: var(--white);
		transform: translateY(0rem);
	}

	.cu-steps .cu-item.ing {
		/* content: "\e645"; */
		/* font-family: "cuIcon"; */
		color: var(--blue);
		transform: translateY(0rem);
	}

	.cu-steps .cu-item[class*="text-"] .num.err::after {
		content: "\e646";
	}

	/* ==================
	          布局
	 ==================== */

	/*  -- flex弹性布局 -- */

	.flex {
		display: flex;
	}

	.basis-xs {
		flex-basis: 20%;
	}

	.basis-sm {
		flex-basis: 40%;
	}

	.basis-df {
		flex-basis: 50%;
	}

	.basis-lg {
		flex-basis: 60%;
	}

	.basis-xl {
		flex-basis: 80%;
	}

	.flex-sub {
		flex: 1;
	}

	.flex-twice {
		flex: 2;
	}

	.flex-treble {
		flex: 3;
	}

	.flex-direction {
		flex-direction: column;
	}

	.flex-wrap {
		flex-wrap: wrap;
	}

	.align-start {
		align-items: flex-start;
	}

	.align-end {
		align-items: flex-end;
	}

	.align-center {
		align-items: center;
	}

	.align-stretch {
		align-items: stretch;
	}

	.self-start {
		align-self: flex-start;
	}

	.self-center {
		align-self: flex-center;
	}

	.self-end {
		align-self: flex-end;
	}

	.self-stretch {
		align-self: stretch;
	}

	.align-stretch {
		align-items: stretch;
	}

	.justify-start {
		justify-content: flex-start;
	}

	.justify-end {
		justify-content: flex-end;
	}

	.justify-center {
		justify-content: center;
	}

	.justify-between {
		justify-content: space-between;
	}

	.justify-around {
		justify-content: space-around;
	}

	/* grid布局 */

	.grid {
		display: flex;
		flex-wrap: wrap;
	}

	.grid.grid-square {
		overflow: hidden;
	}

	.grid.grid-square .cu-tag {
		position: absolute;
		right: 0;
		top: 0;
		border-bottom-left-radius: 6rem;
		padding: 6rem 12rem;
		height: auto;
		background-color: rgba(0, 0, 0, 0.5);
	}

	.grid.grid-square>view>text[class*="cuIcon-"] {
		font-size: 52rem;
		position: absolute;
		color: var(--grey);
		margin: auto;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
	}

	.grid.grid-square>view {
		margin-right: 20rem;
		margin-bottom: 20rem;
		border-radius: 6rem;
		position: relative;
		overflow: hidden;
	}

	.grid.grid-square>view.bg-img image {
		width: 100%;
		height: 100%;
		position: absolute;
	}

	.grid.col-1.grid-square>view {
		padding-bottom: 100%;
		height: 0;
		margin-right: 0;
	}

	.grid.col-2.grid-square>view {
		padding-bottom: calc((100% - 20rem)/2);
		height: 0;
		width: calc((100% - 20rem)/2);
	}

	.grid.col-3.grid-square>view {
		padding-bottom: calc((100% - 40rem)/3);
		height: 0;
		width: calc((100% - 40rem)/3);
	}

	.grid.col-4.grid-square>view {
		padding-bottom: calc((100% - 60rem)/4);
		height: 0;
		width: calc((100% - 60rem)/4);
	}

	.grid.col-5.grid-square>view {
		padding-bottom: calc((100% - 80rem)/5);
		height: 0;
		width: calc((100% - 80rem)/5);
	}

	.grid.col-2.grid-square>view:nth-child(2n),
	.grid.col-3.grid-square>view:nth-child(3n),
	.grid.col-4.grid-square>view:nth-child(4n),
	.grid.col-5.grid-square>view:nth-child(5n) {
		margin-right: 0;
	}

	.grid.col-1>view {
		width: 100%;
	}

	.grid.col-2>view {
		width: 50%;
	}

	.grid.col-3>view {
		width: 33.33%;
	}

	.grid.col-4>view {
		width: 25%;
	}

	.grid.col-5>view {
		width: 20%;
	}

	/*  -- 内外边距 -- */

	.margin-0 {
		margin: 0;
	}

	.margin-xs {
		margin: 10rem;
	}

	.margin-sm {
		margin: 20rem;
	}

	.margin {
		margin: 30rem;
	}

	.margin-lg {
		margin: 40rem;
	}

	.margin-xl {
		margin: 50rem;
	}

	.margin-top-xs {
		margin-top: 10rem;
	}

	.margin-top-sm {
		margin-top: 20rem;
	}

	.margin-top {
		margin-top: 30rem;
	}

	.margin-top-lg {
		margin-top: 40rem;
	}

	.margin-top-xl {
		margin-top: 50rem;
	}

	.margin-right-xs {
		margin-right: 10rem;
	}

	.margin-right-sm {
		margin-right: 20rem;
	}

	.margin-right {
		margin-right: 30rem;
	}

	.margin-right-lg {
		margin-right: 40rem;
	}

	.margin-right-xl {
		margin-right: 50rem;
	}

	.margin-bottom-xs {
		margin-bottom: 10rem;
	}

	.margin-bottom-sm {
		margin-bottom: 20rem;
	}

	.margin-bottom {
		margin-bottom: 30rem;
	}

	.margin-bottom-lg {
		margin-bottom: 40rem;
	}

	.margin-bottom-xl {
		margin-bottom: 50rem;
	}

	.margin-left-xs {
		margin-left: 10rem;
	}

	.margin-left-sm {
		margin-left: 20rem;
	}

	.margin-left {
		margin-left: 30rem;
	}

	.margin-left-lg {
		margin-left: 40rem;
	}

	.margin-left-xl {
		margin-left: 50rem;
	}

	.margin-lr-xs {
		margin-left: 10rem;
		margin-right: 10rem;
	}

	.margin-lr-sm {
		margin-left: 20rem;
		margin-right: 20rem;
	}

	.margin-lr {
		margin-left: 30rem;
		margin-right: 30rem;
	}

	.margin-lr-lg {
		margin-left: 40rem;
		margin-right: 40rem;
	}

	.margin-lr-xl {
		margin-left: 50rem;
		margin-right: 50rem;
	}

	.margin-tb-xs {
		margin-top: 10rem;
		margin-bottom: 10rem;
	}

	.margin-tb-sm {
		margin-top: 20rem;
		margin-bottom: 20rem;
	}

	.margin-tb {
		margin-top: 30rem;
		margin-bottom: 30rem;
	}

	.margin-tb-lg {
		margin-top: 40rem;
		margin-bottom: 40rem;
	}

	.margin-tb-xl {
		margin-top: 50rem;
		margin-bottom: 50rem;
	}

	.padding-0 {
		padding: 0;
	}

	.padding-xs {
		padding: 10rem;
	}

	.padding-sm {
		padding: 20rem;
	}

	.padding {
		padding: 30rem;
	}

	.padding-lg {
		padding: 40rem;
	}

	.padding-xl {
		padding: 50rem;
	}

	.padding-top-xs {
		padding-top: 10rem;
	}

	.padding-top-sm {
		padding-top: 20rem;
	}

	.padding-top {
		padding-top: 30rem;
	}

	.padding-top-lg {
		padding-top: 40rem;
	}

	.padding-top-xl {
		padding-top: 50rem;
	}

	.padding-right-xs {
		padding-right: 10rem;
	}

	.padding-right-sm {
		padding-right: 20rem;
	}

	.padding-right {
		padding-right: 30rem;
	}

	.padding-right-lg {
		padding-right: 40rem;
	}

	.padding-right-xl {
		padding-right: 50rem;
	}

	.padding-bottom-xs {
		padding-bottom: 10rem;
	}

	.padding-bottom-sm {
		padding-bottom: 20rem;
	}

	.padding-bottom {
		padding-bottom: 30rem;
	}

	.padding-bottom-lg {
		padding-bottom: 40rem;
	}

	.padding-bottom-xl {
		padding-bottom: 50rem;
	}

	.padding-left-xs {
		padding-left: 10rem;
	}

	.padding-left-sm {
		padding-left: 20rem;
	}

	.padding-left {
		padding-left: 30rem;
	}

	.padding-left-lg {
		padding-left: 40rem;
	}

	.padding-left-xl {
		padding-left: 50rem;
	}

	.padding-lr-xs {
		padding-left: 10rem;
		padding-right: 10rem;
	}

	.padding-lr-sm {
		padding-left: 20rem;
		padding-right: 20rem;
	}

	.padding-lr {
		padding-left: 30rem;
		padding-right: 30rem;
	}

	.padding-lr-lg {
		padding-left: 40rem;
		padding-right: 40rem;
	}

	.padding-lr-xl {
		padding-left: 50rem;
		padding-right: 50rem;
	}

	.padding-tb-xs {
		padding-top: 10rem;
		padding-bottom: 10rem;
	}

	.padding-tb-sm {
		padding-top: 20rem;
		padding-bottom: 20rem;
	}

	.padding-tb {
		padding-top: 30rem;
		padding-bottom: 30rem;
	}

	.padding-tb-lg {
		padding-top: 40rem;
		padding-bottom: 40rem;
	}

	.padding-tb-xl {
		padding-top: 50rem;
		padding-bottom: 50rem;
	}

	/* -- 浮动 --  */

	.cf::after,
	.cf::before {
		content: " ";
		display: table;
	}

	.cf::after {
		clear: both;
	}

	.fl {
		float: left;
	}

	.fr {
		float: right;
	}

	/* ==================
	          背景
	 ==================== */

	.line-red::after,
	.lines-red::after {
		border-color: var(--red);
	}

	.line-orange::after,
	.lines-orange::after {
		border-color: var(--orange);
	}

	.line-yellow::after,
	.lines-yellow::after {
		border-color: var(--yellow);
	}

	.line-olive::after,
	.lines-olive::after {
		border-color: var(--olive);
	}

	.line-green::after,
	.lines-green::after {
		border-color: var(--green);
	}

	.line-cyan::after,
	.lines-cyan::after {
		border-color: var(--cyan);
	}

	.line-blue::after,
	.lines-blue::after {
		border-color: var(--blue);
	}

	.line-purple::after,
	.lines-purple::after {
		border-color: var(--purple);
	}

	.line-mauve::after,
	.lines-mauve::after {
		border-color: var(--mauve);
	}

	.line-pink::after,
	.lines-pink::after {
		border-color: var(--pink);
	}

	.line-brown::after,
	.lines-brown::after {
		border-color: var(--brown);
	}

	.line-grey::after,
	.lines-grey::after {
		border-color: var(--grey);
	}

	.line-gray::after,
	.lines-gray::after {
		border-color: var(--gray);
	}

	.line-black::after,
	.lines-black::after {
		border-color: var(--black);
	}

	.line-white::after,
	.lines-white::after {
		border-color: var(--white);
	}

	.bg-red {
		background-color: var(--red);
		color: var(--white);
	}

	.bg-orange {
		background-color: var(--orange);
		color: var(--white);
	}

	.bg-yellow {
		background-color: var(--yellow);
		color: var(--black);
	}

	.bg-olive {
		background-color: var(--olive);
		color: var(--white);
	}

	.bg-green {
		background-color: var(--green);
		color: var(--white);
	}

	.bg-cyan {
		background-color: var(--cyan);
		color: var(--white);
	}

	.bg-blue {
		background-color: var(--blue);
		color: var(--white);
	}

	.bg-steelblue {
		background-color: steelblue;
		color: var(--white);
	}

	.bg-purple {
		background-color: var(--purple);
		color: var(--white);
	}

	.bg-mauve {
		background-color: var(--mauve);
		color: var(--white);
	}

	.bg-pink {
		background-color: var(--pink);
		color: var(--white);
	}

	.bg-brown {
		background-color: var(--brown);
		color: var(--white);
	}

	.bg-grey {
		background-color: var(--grey);
		color: var(--white);
	}

	.bg-gray {
		background-color: #f0f0f0;
		color: var(--black);
	}

	.bg-black {
		background-color: var(--black);
		color: var(--white);
	}

	.bg-white {
		background-color: var(--white);
		color: var(--darkGray);
	}

	.bg-shadeTop {
		background-image: linear-gradient(rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.01));
		color: var(--white);
	}

	.bg-shadeBottom {
		background-image: linear-gradient(rgba(0, 0, 0, 0.01), rgba(0, 0, 0, 1));
		color: var(--white);
	}

	.bg-red.light {
		color: var(--red);
		background-color: var(--redLight);
	}

	.bg-orange.light {
		color: var(--orange);
		background-color: var(--orangeLight);
	}

	.bg-yellow.light {
		color: var(--yellow);
		background-color: var(--yellowLight);
	}

	.bg-olive.light {
		color: var(--olive);
		background-color: var(--oliveLight);
	}

	.bg-green.light {
		color: var(--green);
		background-color: var(--greenLight);
	}

	.bg-cyan.light {
		color: var(--cyan);
		background-color: var(--cyanLight);
	}

	.bg-blue.light {
		color: var(--blue);
		background-color: var(--blueLight);
	}

	.bg-purple.light {
		color: var(--purple);
		background-color: var(--purpleLight);
	}

	.bg-mauve.light {
		color: var(--mauve);
		background-color: var(--mauveLight);
	}

	.bg-pink.light {
		color: var(--pink);
		background-color: var(--pinkLight);
	}

	.bg-brown.light {
		color: var(--brown);
		background-color: var(--brownLight);
	}

	.bg-grey.light {
		color: var(--grey);
		background-color: var(--greyLight);
	}

	.bg-gradual-red {
		background-image: var(--gradualRed);
		color: var(--white);
	}

	.bg-gradual-orange {
		background-image: var(--gradualOrange);
		color: var(--white);
	}

	.bg-gradual-green {
		background-image: var(--gradualGreen);
		color: var(--white);
	}

	.bg-gradual-purple {
		background-image: var(--gradualPurple);
		color: var(--white);
	}

	.bg-gradual-pink {
		background-image: var(--gradualPink);
		color: var(--white);
	}

	.bg-gradual-blue {
		/* background-image: var(--gradualBlue); */
		color: var(--white);
	}

	.shadow[class*="-red"] {
		box-shadow: var(--ShadowSize) var(--redShadow);
	}

	.shadow[class*="-orange"] {
		box-shadow: var(--ShadowSize) var(--orangeShadow);
	}

	.shadow[class*="-yellow"] {
		box-shadow: var(--ShadowSize) var(--yellowShadow);
	}

	.shadow[class*="-olive"] {
		box-shadow: var(--ShadowSize) var(--oliveShadow);
	}

	.shadow[class*="-green"] {
		box-shadow: var(--ShadowSize) var(--greenShadow);
	}

	.shadow[class*="-cyan"] {
		box-shadow: var(--ShadowSize) var(--cyanShadow);
	}

	.shadow[class*="-blue"] {
		box-shadow: var(--ShadowSize) var(--blueShadow);
	}

	.shadow[class*="-purple"] {
		box-shadow: var(--ShadowSize) var(--purpleShadow);
	}

	.shadow[class*="-mauve"] {
		box-shadow: var(--ShadowSize) var(--mauveShadow);
	}

	.shadow[class*="-pink"] {
		box-shadow: var(--ShadowSize) var(--pinkShadow);
	}

	.shadow[class*="-brown"] {
		box-shadow: var(--ShadowSize) var(--brownShadow);
	}

	.shadow[class*="-grey"] {
		box-shadow: var(--ShadowSize) var(--greyShadow);
	}

	.shadow[class*="-gray"] {
		box-shadow: var(--ShadowSize) var(--grayShadow);
	}

	.shadow[class*="-black"] {
		box-shadow: var(--ShadowSize) var(--blackShadow);
	}

	.shadow[class*="-white"] {
		box-shadow: var(--ShadowSize) var(--blackShadow);
	}

	.text-shadow[class*="-red"] {
		text-shadow: var(--ShadowSize) var(--redShadow);
	}

	.text-shadow[class*="-orange"] {
		text-shadow: var(--ShadowSize) var(--orangeShadow);
	}

	.text-shadow[class*="-yellow"] {
		text-shadow: var(--ShadowSize) var(--yellowShadow);
	}

	.text-shadow[class*="-olive"] {
		text-shadow: var(--ShadowSize) var(--oliveShadow);
	}

	.text-shadow[class*="-green"] {
		text-shadow: var(--ShadowSize) var(--greenShadow);
	}

	.text-shadow[class*="-cyan"] {
		text-shadow: var(--ShadowSize) var(--cyanShadow);
	}

	.text-shadow[class*="-blue"] {
		text-shadow: var(--ShadowSize) var(--blueShadow);
	}

	.text-shadow[class*="-purple"] {
		text-shadow: var(--ShadowSize) var(--purpleShadow);
	}

	.text-shadow[class*="-mauve"] {
		text-shadow: var(--ShadowSize) var(--mauveShadow);
	}

	.text-shadow[class*="-pink"] {
		text-shadow: var(--ShadowSize) var(--pinkShadow);
	}

	.text-shadow[class*="-brown"] {
		text-shadow: var(--ShadowSize) var(--brownShadow);
	}

	.text-shadow[class*="-grey"] {
		text-shadow: var(--ShadowSize) var(--greyShadow);
	}

	.text-shadow[class*="-gray"] {
		text-shadow: var(--ShadowSize) var(--grayShadow);
	}

	.text-shadow[class*="-black"] {
		text-shadow: var(--ShadowSize) var(--blackShadow);
	}

	.bg-img {
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
	}

	.bg-mask {
		background-color: var(--black);
		position: relative;
	}

	.bg-mask::after {
		content: "";
		border-radius: inherit;
		width: 100%;
		height: 100%;
		display: block;
		background-color: rgba(0, 0, 0, 0.4);
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		top: 0;
	}

	.bg-mask view,
	.bg-mask cover-view {
		z-index: 5;
		position: relative;
	}

	.bg-video {
		position: relative;
	}

	.bg-video video {
		display: block;
		height: 100%;
		width: 100%;
		-o-object-fit: cover;
		object-fit: cover;
		position: absolute;
		top: 0;
		z-index: 0;
		pointer-events: none;
	}

	/* ==================
	          文本
	 ==================== */

	.text-xs {
		font-size: 20rem;
	}

	.text-sm {
		font-size: 24rem;
	}

	.text-df {
		font-size: 28rem;
	}

	.text-lg {
		font-size: 32rem;
	}

	.text-xl {
		font-size: 36rem;
	}

	.text-xxl {
		font-size: 44rem;
	}

	.text-sl {
		font-size: 80rem;
	}

	.text-xsl {
		font-size: 120rem;
	}

	.text-Abc {
		text-transform: Capitalize;
	}

	.text-ABC {
		text-transform: Uppercase;
	}

	.text-abc {
		text-transform: Lowercase;
	}

	.text-price::before {
		content: "¥";
		font-size: 80%;
		margin-right: 4rem;
	}

	.text-cut {
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
	}

	.text-bold {
		font-weight: bold;
	}

	.text-center {
		text-align: center;
	}

	.text-content {
		line-height: 1.6;
	}

	.text-left {
		text-align: left;
	}

	.text-right {
		text-align: right;
	}

	.text-red,
	.line-red,
	.lines-red {
		color: var(--red);
	}

	.text-orange,
	.line-orange,
	.lines-orange {
		color: var(--orange);
	}

	.text-yellow,
	.line-yellow,
	.lines-yellow {
		color: var(--yellow);
	}

	.text-olive,
	.line-olive,
	.lines-olive {
		color: var(--olive);
	}

	.text-green,
	.line-green,
	.lines-green {
		color: var(--green);
	}

	.text-cyan,
	.line-cyan,
	.lines-cyan {
		color: var(--cyan);
	}

	.text-blue,
	.line-blue,
	.lines-blue {
		color: var(--blue);
	}

	.text-steelblue,
	.line-steelblue,
	.lines-steelblue {
		color: steelblue;
	}

	.text-purple,
	.line-purple,
	.lines-purple {
		color: var(--purple);
	}

	.text-mauve,
	.line-mauve,
	.lines-mauve {
		color: var(--mauve);
	}

	.text-pink,
	.line-pink,
	.lines-pink {
		color: var(--pink);
	}

	.text-brown,
	.line-brown,
	.lines-brown {
		color: var(--brown);
	}

	.text-grey,
	.line-grey,
	.lines-grey {
		color: var(--grey);
	}

	.text-gray,
	.line-gray,
	.lines-gray {
		color: var(--gray);
	}

	.text-black,
	.line-black,
	.lines-black {
		color: var(--black);
	}

	.text-white,
	.line-white,
	.lines-white {
		color: var(--white);
	}

	.cu-avatar {
		font-variant: small-caps;
		margin: 0;
		padding: 0;
		display: inline-flex;
		text-align: center;
		justify-content: center;
		align-items: center;
		background-color: #ccc;
		color: var(--white);
		white-space: nowrap;
		position: relative;
		width: 64rem;
		height: 64rem;
		background-size: cover;
		background-position: center;
		vertical-align: middle;
		font-size: 1.5em;
	}

	.eqName {
		width: 12vw;
		height: 12vw;
		background-color: rgba(70, 131, 180, 0.3);
		border-radius: 16rem;
		border: 1rem solid steelblue;
		display: flex;
		justify-content: center;
		align-items: center;
		color: #666;
		font-size: 30rem;
		position: relative;
	}

	.todaySales {
		width: 50%;
		text-align: center;
		justify-content: space-between;
		color: #ffffff;
		font-size: 24rem;
		border-right: 1rem solid #aaa;
	}

	.todaySales:last-child {
		border-right: unset !important;
	}

	.todaySales .Up {
		font-size: 40rem;
	}

	.todaySales .down {
		font-size: 24rem;
		letter-spacing: 0rem
	}

	.margin-left-xs {
		margin-left: 10rem;
	}

	.margin-right-xs {
		margin-right: 10rem;
	}

	.text-light {
		font-weight: 300;
	}

	.center {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.flex {
		display: flex;
	}

	.flexLeft {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
	}

	.flexRight {
		display: flex;
		flex-direction: column;
		align-items: flex-end;
	}

	.flex-Hcenter {
		display: flex;
		align-items: center;
	}

	.flex-direction {
		display: flex;
		flex-direction: column;
	}

	.flexColumn {
		display: flex;
		flex-direction: column;
	}

	.text-white {
		color: #ffffff;
	}

	/* vant */
	.van-tab {
		font-size: 28rem !important;
		line-height: 40rem !important;
		padding: 0 8rem !important;
	}

	.van-cell {
		padding: 20rem 32rem !important;
		font-size: 28rem !important;
		line-height: 48rem !important;
	}

	.van-cell-group__title--inset {
		padding: 32rem 32rem 16rem 64rem !important;
	}

	.van-cell__left-icon,
	.van-cell__right-icon {
		height: 48rem !important;
		font-size: 32rem !important;
		line-height: 48rem !important;
	}

	.van-cell-group__title {
		padding: 32rem 32rem 16rem !important;
		color: #969799;
		font-size: 28rem !important;
		line-height: 32rem !important;
	}

	.van-cell__label {
		margin-top: 8rem !important;
		color: #969799;
		font-size: 24rem !important;
		line-height: 36rem !important;
	}

	.van-stepper__minus,
	.van-stepper__plus {
		position: relative;
		box-sizing: border-box;
		width: 56rem !important;
		height: 56rem !important;
		margin: 0;
		padding: 0;
		color: #323233;
		vertical-align: middle;
		background-color: #f2f3f5;
		border: 0;
		cursor: pointer;
	}

	.van-stepper__input {
		box-sizing: border-box;
		width: 62rem !important;
		height: 56rem !important;
		margin: 0 4rem !important;
		padding: 0;
		color: #323233;
		font-size: 28rem !important;
		line-height: normal;
		text-align: center;
		vertical-align: middle;
		background-color: #f2f3f5;
		border: 0;
		border-width: 2rem 0 !important;
		border-radius: 0;
		-webkit-appearance: none;
	}

	.van-radio__label {
		margin-left: 16rem !important;
		color: #323233;
		line-height: 40rem !important;
	}

	.van-tabs__line {
		position: absolute;
		bottom: 30rem !important;
		left: 0;
		z-index: 1;
		width: 80rem !important;
		height: 6rem !important;
		background-color: #ee0a24;
		border-radius: 6rem !important;
	}

	.van-tabs__nav--line {
		box-sizing: content-box;
		height: 100%;
		padding-bottom: 30rem !important;
	}

	.van-tabs--line .van-tabs__wrap {
		height: 88rem !important;
	}

	.van-tabbar-item {
		font-size: 24rem !important;
	}

	.van-tabbar-item__icon {
		position: relative;
		margin-bottom: 8rem !important;
		font-size: 44rem !important;
	}

	.van-tabbar {
		z-index: 1;
		display: -webkit-box;
		display: -webkit-flex;
		display: flex;
		box-sizing: content-box;
		width: 100%;
		height: 100rem !important;
		padding-bottom: constant(safe-area-inset-bottom);
		padding-bottom: env(safe-area-inset-bottom);
		background-color: #fff;
	}

	.van-tag--medium {
		padding: 4rem 12rem !important;
	}

	.van-tag {
		position: relative;
		display: -webkit-inline-box;
		display: -webkit-inline-flex;
		display: inline-flex;
		-webkit-box-align: center;
		-webkit-align-items: center;
		align-items: center;
		padding: 0 8rem !important;
		color: #fff;
		font-size: 24rem !important;
		line-height: 32rem !important;
	}

	.van-button--small {
		height: 64rem !important;
		padding: 0 16rem !important;
		font-size: 24rem !important;
	}

	.van-switch {
		position: relative;
		display: inline-block;
		box-sizing: content-box;
		width: 4em;
		height: 2em;
		font-size: 60rem !important;
		background-color: #fff;
		border: 1rem solid rgba(0, 0, 0, .1);
		border-radius: 2em;
		cursor: pointer;
		-webkit-transition: background-color .3s;
		transition: background-color .3s;
	}

	.van-picker__cancel,
	.van-picker__confirm {
		height: 100%;
		padding: 0 32rem !important;
		font-size: 28rem !important;
		background-color: transparent;
		border: none;
		cursor: pointer;
	}

	.van-picker-column {
		-webkit-box-flex: 1;
		-webkit-flex: 1;
		flex: 1;
		overflow: hidden;
		font-size: 32rem !important;
	}

	.van-picker-column__item {
		display: -webkit-box;
		display: -webkit-flex;
		display: flex;
		-webkit-box-align: center;
		-webkit-align-items: center;
		align-items: center;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		justify-content: center;
		padding: 0 8rem !important;
		color: #000;

	}

	.van-picker__toolbar {
		display: -webkit-box;
		display: -webkit-flex;
		display: flex;
		-webkit-box-align: center;
		-webkit-align-items: center;
		align-items: center;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		justify-content: space-between;
		height: 88rem !important;
	}

	.van-search .van-cell {
		-webkit-box-flex: 1;
		-webkit-flex: 1;
		flex: 1;
		padding: 10rem 16rem 10rem 0 !important;
		background-color: transparent;
	}

	.van-toast {
		position: fixed;
		top: 50%;
		left: 50%;
		display: -webkit-box;
		display: -webkit-flex;
		display: flex;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: center;
		-webkit-align-items: center;
		align-items: center;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		justify-content: center;
		box-sizing: content-box;
		width: 176rem !important;
		max-width: 70%;
		min-height: 88rem !important;
		padding: 16rem !important;
		color: #fff;
		font-size: 28rem !important;
		line-height: 80rem !important;
		white-space: pre-wrap;
		text-align: center;
		word-break: break-all;
		background-color: rgba(0, 0, 0, .7);
		border-radius: 16rem !important;
		-webkit-transform: translate3d(-50%, -50%, 0);
		transform: translate3d(-50%, -50%, 0);
	}

	.van-dropdown-menu__bar {
		position: relative;
		display: -webkit-box;
		display: -webkit-flex;
		display: flex;
		height: unset !important;
		background-color: #fff;
		box-shadow: unset !important;
	}

	.van-dropdown-menu__title {
		position: relative;
		box-sizing: border-box;
		max-width: 100%;
		padding: 0 16rem !important;
		color: #323233;
		font-size: 30rem !important;
		line-height: 44rem !important;
	}

	.van-search {
		display: -webkit-box;
		display: -webkit-flex;
		display: flex;
		-webkit-box-align: center;
		-webkit-align-items: center;
		align-items: center;
		box-sizing: border-box;
		padding: 20rem 24rem !important;
		background-color: #fff;
	}

	.van-search__content {
		display: -webkit-box;
		display: -webkit-flex;
		display: flex;
		-webkit-box-flex: 1;
		-webkit-flex: 1;
		flex: 1;
		padding-left: 24rem !important;
		background-color: #f7f8fa;
		border-radius: 50rem !important;
	}

	.van-field__left-icon {
		margin-right: 8rem !important;
	}

	.van-field__left-icon .van-icon,
	.van-field__right-icon .van-icon {
		display: block;
		font-size: 32rem !important;
		line-height: inherit;
	}

	.van-button--normal {
		padding: 0 30rem !important;
		font-size: 28rem !important;
	}

	.van-button {
		position: relative;
		display: inline-block;
		box-sizing: border-box;
		height: 88rem !important;
		margin: 0;
		padding: 0;
		font-size: 32rem !important;
		line-height: 1.2;
		text-align: center;
		border-radius: 4rem !important;
		cursor: pointer;
		-webkit-transition: opacity .2s;
		transition: opacity .2s;
		-webkit-appearance: none;
	}

	.van-empty__image {
		width: 320rem !important;
		height: 320rem !important;

	}

	.van-empty__description {
		margin-top: 32rem !important;
		padding: 0 120rem !important;
		color: #969799;
		font-size: 28rem !important;
		line-height: 40rem !important;
	}

	/* body,#app{
	width: 100%;
	height: 100%;
} */
</style>
